import React from "react"
import { graphql, StaticQuery } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"

import ReactPlayer from "react-player"

import "../utils/normalize.css"
import "../utils/css/screen.css"

const FilmPage = ({ data }, location) => {
  const siteTitle = data.site.siteMetadata.title

  return (
    <Layout title={siteTitle}>
      <SEO title="Film" keywords={[`blog`, `gatsby`, `javascript`, `react`]} />

      <article className="post-content page-template no-image">
        <div className="post-content-body">
          <h2>Film</h2>
          <p>
            Cinema is the art of the polymath.{" "}
            <a href="https://medium.com/@toledoalbert/cinema-the-art-of-the-polymath-f793a3a76e25">
              Here is an essay
            </a>{" "}
            where I explain why I would dedicate my life to cinema if I could
            only do one thing in life. Luckily I don't have to.
          </p>
          <hr></hr>
          <h4>Once upon a time on the Island</h4>
          <p>
            I am currently writing the screenplay for this feature length movie.
            Once upon a time on the Island is the story of two friends who grew
            up in Burgazada. One of them now lives in New York while the other
            one stayed in Istanbul. As they go about their magical but routine
            day on the island and discuss what the island means for them, we
            explore human's relationship to places and what it means to leave.
          </p>
          <div className="row">
            <ReactPlayer width="100%" url="https://vimeo.com/474525483" />
          </div>
          <hr></hr>
          <h4>In Between</h4>
          <p>
            In Between is my first narrative short film. It is the story of
            Vittoria, an Italian student in New York City. She struggles to feel
            home, looking forward to be back in Italy soon. However when she
            returns, she realizes the genie is out of the bottle.
          </p>
          <div className="row">
            <ReactPlayer width="100%" url="https://vimeo.com/296217201" />
          </div>
          <hr></hr>
          <h4>Istanbul is a Daydream</h4>
          <p>
            This is a short video I made after one of my regular trips to
            Istanbul to express the feeling that once I am back in New York
            City, my trip home seems like a distant dream.
          </p>
          <div className="row">
            <ReactPlayer width="100%" url="https://youtu.be/scjAUbJjMVU" />
          </div>
          <hr></hr>
          <h4>Teaser for AFS Turkey Magazine</h4>
          <p>
            Back in 2010 as a team of AFS volunteers in Turkey we started a
            magazine for our organization. The magazine was to reflect the
            worldviews and experience of us all volunteers. Each of us had spent
            a year abroad in different countries via AFS Intercultural Progams.
            So I came up with this superhero character that travels and reads
            magazines all around the world before he comes back and creates the
            AFS Magazine. It is a stop motion animation made out of paper.
          </p>
          <div className="row">
            <ReactPlayer width="100%" url="https://vimeo.com/21725460" />
          </div>
        </div>
      </article>
    </Layout>
  )
}

const indexQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    benchAccounting: file(
      relativePath: { eq: "bench-accounting-49909-unsplash.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1360) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default props => (
  <StaticQuery
    query={indexQuery}
    render={data => (
      <FilmPage location={props.location} data={data} {...props} />
    )}
  />
)
